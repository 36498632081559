import React from "react";
import GatsbyLink from "../../GatsbyLink";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./PostListItem.scss";
import { decodeEntities } from "../../../utils/helpers";
import { dateI18n } from "@wordpress/date";

export default (props) => {
  const { siteMetadata, data, pathPrefix, showAuthor, dateFormat } = props;
  const { excerpt, title, slug, path, featured_media, author, date } = data;
  const dateFormatted = dateFormat && date && dateI18n(dateFormat, date);
  return (
    <div className="post-list-item">
      {dateFormatted && <span className="date">{dateFormatted}</span>}
      <h4 className="post-title">
        <GatsbyLink to={path}>{decodeEntities(title)}</GatsbyLink>
      </h4>
    </div>
  );
};
